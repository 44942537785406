import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { addToastInfo } from 'src/store/common';
import useAfterBooking from 'src/hooks/use-after-booking';
import { captureException } from '@sentry/react';
import { useLocation } from 'react-router-dom';
import i18n from 'src/translations/i18n';
import {
  OFFERING_FILTERS,
  OfferingFilters,
} from 'src/pages/group-sessions/offering-list/filters/use-offering-filters';
import useSearchParams from 'src/hooks/use-search-params';
import backendApi from './config/backend';
import { EventType, IBooking, IBookingEvent, IBookingHistory } from '../types';
import { queryClient } from './config/react-query';

export type BookingSource =
  | 'WEBAPP'
  | 'WEBAPP_PE_DRAWER'
  | 'WEBAPP_LIBRARY_DRAWER'
  | 'EMAIL_RECOMMENDATION_NEWSLETTER'
  | 'EMAIL_RECOMMENDATION_CLICKED'
  | 'WEBAPP_PREBOOKED_EVENT'
  | 'WEBAPP_RECOMMENDED_TAB'
  | 'WEBAPP_GROUP_SESSION_TAB'
  | 'WEBAPP_INDIVIDUAL_SESSION_TAB'
  | 'WEBAPP_BOOKINGS_PAGE'
  | 'EMAIL'
  | 'OTHER_DATE'
  | 'WEBAPP_GROUP_SESSION_TAB_ONE_CLICK'
  | 'WEBAPP_LEADERSHIP_GROUP_SESSION_TAB'
  | 'WEBAPP_LEADERSHIP_INDIVIDUAL_SESSION_TAB';

export type WebappBookingSource =
  | 'LIBRARY_MEDITATION_DRAWER'
  | 'LIBRARY_READING_DRAWER'
  | 'LIBRARY_EXERCISE_DRAWER'
  | 'LIBRARY_VIDEO_SHORT_DRAWER'
  | 'LIBRARY_VIDEO_WEBINAR_DRAWER';

export type CancellationReason =
  | 'TIMING'
  | 'SICK'
  | 'NOT_RELEVANT'
  | 'OTHER'
  | 'ADMIN_CANCELLATION'
  | 'UNKNOWN';

const getBookings = async (): Promise<IBooking[]> => {
  const result = await backendApi.get(`/bookings/my-bookings`);
  return result.data;
};

const getLastBooking = async (): Promise<IBookingEvent[]> => {
  const result = await backendApi.get(`/bookings/last-booking`);
  return result.data;
};

const getBookingHistory = async (): Promise<IBookingHistory> => {
  const result = await backendApi.get(`/bookings/me`);

  return result?.data ?? [];
};

const cancelSessionBooking = async (parameters: {
  sessionInviteId: string;
  sessionCancellationReason: CancellationReason;
}) => {
  const result = await backendApi.post(
    `/bookings/cancel-session-booking`,
    parameters,
  );

  return result;
};

export const useGetBookings = () =>
  useQuery<IBooking[]>(['bookings'], getBookings);

export const useGetLastBooking = () =>
  useQuery(['last-booking'], getLastBooking);

export const useGetBookingHistory = () =>
  useQuery(['bookingHistory'], getBookingHistory, {
    select: (data) => data?.filter((booking) => !booking?.cancelled),
  });

const createBooking = async (parameters: CreateBooking) => {
  const result = await backendApi.post(`/bookings/create-booking`, parameters);
  return result;
};

interface CreateBooking {
  offeringId: string;
  starter?: boolean;
  bookingContext?: {
    filters?: Record<OfferingFilters, string | string[]> | object;
    bookingSource?: BookingSource;
    offeringRanking?: number;
    webappBookingSource?: WebappBookingSource;
  };
}

interface UseCreateBooking {
  onClose?: () => void;
  oneClickBooking?: boolean;
}

type BookOfferingParams = IBookingEvent & { bookingSource: BookingSource };

export const useCreateBooking = ({
  onClose,
  oneClickBooking,
}: UseCreateBooking) => {
  const { handleBook } = useAfterBooking({ onClose, oneClickBooking });
  const dispatch = useDispatch();
  const { getParams } = useSearchParams();
  const filtersFromUrl = getParams(OFFERING_FILTERS);
  const filters = Object.entries(filtersFromUrl).reduce(
    (acc, [key, value]) =>
      key === 'search'
        ? { ...acc, [key]: value }
        : { ...acc, [key]: value.split(',') },
    {},
  );
  const location = useLocation();

  return useMutation(
    (params: BookOfferingParams) =>
      createBooking({
        offeringId: params?.id,
        starter: params?.isBeginnerFriendly,
        bookingContext: {
          filters,
          bookingSource: params.bookingSource,
          webappBookingSource: location?.state?.webappBookingSource,
          offeringRanking: params?.ranking,
        },
      }),
    {
      onSuccess: () => {
        handleBook();
        queryClient.invalidateQueries(['bookings']);
      },
      onError: (error) => {
        captureException(error);
        dispatch(
          addToastInfo({
            toastInfo: {
              open: true,
              type: 'error',
              content: i18n.t('UseApi.UnexpectedError'),
            },
          }),
        );
      },
    },
  );
};

export const useCancelBooking = (event: IBooking, callback?: () => void) => {
  const isIndividualSession = event.type === EventType.ONE_ON_ONE;
  const dispatch = useDispatch();

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['bookings'] });

    dispatch(
      addToastInfo({
        toastInfo: {
          open: true,
          type: 'warning',
          content: i18n.t('v2.global.snackbar_label_cancelledAttendance'),
        },
      }),
    );
  };

  const { mutate: cancelSession } = useMutation(cancelSessionBooking, {
    onSuccess,
  });

  const onCancelSession = (sessionCancellationReason: CancellationReason) => {
    if (typeof callback === 'function') {
      callback();
    }

    if (isIndividualSession) {
      window.open(event.cancelUrl, '_blank');
      return;
    }

    return cancelSession({
      sessionInviteId: event.sessionInviteId,
      sessionCancellationReason,
    });
  };

  return onCancelSession;
};
